.root {
    background: rgba(255, 255, 255, 1);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    &.animOff {
        [data-animation="opacity"] {
            opacity: 1 !important;
        }
    }
}

.result {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
